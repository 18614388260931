import React from 'react';

import Layout from '../templates/layout-main';
import SEO from '../atoms/seo';

import globalData from '../../../../content/siteMetadata.json';

class IndexPage extends React.Component {

  state = {
    data: globalData
  };

  render() {
    return (
      this.state.data ?
        <Layout data={this.state.data}>
          <SEO title="Flourish ideas" />
        </Layout>
        :
        <div>Loading</div>
    );
  }
};

export default IndexPage;
